:root {
    --glow-hue: 222deg;
    --shadow-hue: 180deg;
    --spring-easing: linear(
    0, 0.002, 0.01 0.9%, 0.038 1.8%, 0.156, 0.312 5.8%, 0.789 11.1%, 1.015 14.2%,
    1.096, 1.157, 1.199, 1.224 20.3%, 1.231, 1.231, 1.226, 1.214 24.6%,
    1.176 26.9%, 1.057 32.6%, 1.007 35.5%, 0.984, 0.968, 0.956, 0.949 42%,
    0.946 44.1%, 0.95 46.5%, 0.998 57.2%, 1.007, 1.011 63.3%, 1.012 68.3%,
    0.998 84%, 1
  );
    --spring-duration: 1.33s;
}
@property --button-shimmer {
    syntax: "<angle>";
    inherits: false;
    initial-value: 33deg;
}

@keyframes shimmer-button-shimmer {
    0% {
        --button-shimmer: 0deg;
    }
    100% {
        --button-shimmer: 360deg;
    }
}

@keyframes shimmer-button-shine {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    55% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
@keyframes shimmer-button-text {
    0% {
        background-position: 100% center;
    }    
    100% {
        background-position: -100% center;
    }    
}

.shimmer-button {
    /* color: hsl(var(--foreground)); */
    color: white;
    font-weight: 600;
    /*     background-image: linear-gradient(90deg, #fcecfe, #fbf6e7, #e6fcf5); */
    /* background-image: linear-gradient(å
        315deg,
        #ffc4ec -10%,
        #efdbfd 50%,
        #ffedd6 110%
    ); */
    /* background: hsl(var(--primary)); */
    background: #0073b1;
    padding: .6rem 1.4rem;
    position: relative;
    isolation: isolate;
    /* box-shadow: 0 2px 3px 1px hsl(var(--glow-hue) 50% 20% / 50%), inset 0 -10px 20px -10px hsla(var(--shadow-hue),10%,90%,95%); */
    box-shadow: 0 2px 10px 1px hsl(var(--glow-hue) 50% 20% / 20%), inset 0 -10px 20px -10px hsla(var(--shadow-hue),10%,90%,95%);
    border-radius: 0.66rem;
    font-size: 1.2rem;
    scale: 1;
    transition: all var(--spring-duration) var(--spring-easing);
}

.shimmer-button:hover:not(:active),
.shimmer-button.active {
    transition-duration: calc(var(--spring-duration)*0.5);
    scale: 1.2;
    box-shadow: 0 4px 8px -2px hsl(var(--glow-hue) 50% 20% / 50%), inset 0 0 0 transparent;
}
.shimmer-button:active {
    scale: 1.1;
    transition-duration: calc(var(--spring-duration)*0.5);
}

.shimmer {
    position: absolute;
    inset: -40px;
    border-radius: inherit;
    mask-image: conic-gradient(
        from var(--button-shimmer, 0deg),
        transparent 0%,
        transparent 10%,
        black 36%,
        black 45%,
        transparent 50%,
        transparent 60%,
        black 85%,
        black 95%,
        transparent 100%
    );
    mask-size: cover;
    mix-blend-mode: plus-lighter;
    animation: shimmer-button-shimmer 1s linear infinite both;
}
.shimmer-button:hover .shimmer::before,
.shimmer-button:hover .shimmer::after,
.shimmer-button.active .shimmer::before,
.shimmer-button.active .shimmer::after {
    opacity: 1;
    animation: shimmer-button-shine 1.2s ease-in 1 forwards infinite;
}
.shimmer::before,
.shimmer::after {
    transition: all 0.5s ease;
    opacity: 0;
    content: "";
    border-radius: inherit;
    position: absolute;
    mix-blend-mode: color;
    inset: 40px;
    pointer-events: none;
}
.shimmer::before {
    box-shadow: 0 0 3px 2px hsl(var(--glow-hue) 20% 95%),
        0 0 7px 4px hsl(var(--glow-hue) 20% 80%),
        0 0 13px 4px hsl(var(--glow-hue) 50% 70%),
        0 0 25px 5px hsl(var(--glow-hue) 100% 70%);
    z-index: -1;
}

.shimmer::after {
    box-shadow: inset 0 0 0 1px hsl(var(--glow-hue) 70% 95%),
        inset 0 0 2px 1px hsl(var(--glow-hue) 100% 80%),
        inset 0 0 5px 2px hsl(var(--glow-hue) 100% 70%);
    z-index: 2;
}

.shimmer-button .shimmer-button-text {
    color: transparent;
    background-clip: text;
    /* background-color: var(hsl(--foreground)); */
    /* background-color: hsl(var(--foreground)); */
    background-color: white;
    background-image: linear-gradient(120deg, transparent, hsla(var(--glow-hue),100%,80%,0.66) 40%, hsla(var(--glow-hue),100%,90%,.9) 50%, transparent 52%);
    background-repeat: no-repeat;
    background-size: 300% 300%;
    background-position: center 200%;
}

.shimmer-button:hover .shimmer-button-text,
.shimmer-button.active .shimmer-button-text {
    animation: shimmer-button-text .66s ease-in 1 both;
}



