.hit-the-floor {
    text-shadow: 
      0 .005em 0 rgba(0, 0, 0, .2), 
      0 .005em 0 currentColor,
      
      0 .01em 0 rgba(0, 0, 0, .25), 
      0 .01em 0 currentColor, 
      
      0 .015em 0 rgba(0, 0, 0, .3),
      0 .015em 0 currentColor,
      
      0 .02em 0 rgba(0, 0, 0, .35), 
      0 .02em 0 currentColor,
       
      0 .025em 0 rgba(0, 0, 0, .4),
      0 .025em 0 currentColor,
      
      0 .03em .005em rgba(0, 0, 0, .1), 
      0 0 .025em rgba(0, 0, 0, .1), 
      0 .005em .015em rgba(0, 0, 0, .3), 
      0 .015em .025em rgba(0, 0, 0, .2), 
      0 .025em .05em rgba(0, 0, 0, .25), 
      0 .05em .05em rgba(0, 0, 0, .2), 
      0 .1em .1em rgba(0, 0, 0, .15);
  }